/* eslint-disable camelcase */
import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Confetti from './Confetti';
import { getUserUUID } from '@buzzfeed/bf-utils/lib/userid';
import { safeEmojis, unsafeEmojis } from '@buzzfeed/react-components/lib/constants/emojis';
import { formatNumberToShorthand } from '../../utils/data';
import { localUserEntries } from '../../utils/user-entries';
import { EmojiReactionAdd } from '../../icons/EmojiReactionAdd';
import { useTrackingContext } from '../../hooks';
import { bfUrl } from '../../constants';
import styles from './emojiReactions.module.scss';

const minReactions = 5;
const userEmojis = localUserEntries({ namespace: 'localUserEmojis' });

const emojisList = [...safeEmojis, ...unsafeEmojis];

const getEndpoint = ({ contentType, contentId }, queryParams = {}) => {
  const url = new URL(`${bfUrl}/content-reactions-api/v2/emoji/${contentType}/${contentId}`);

  // Adding default query parameters
  url.searchParams.set('edition', 'en-us');

  // Adding additional query parameters if provided
  Object.entries(queryParams).forEach(([key, value]) => {
    url.searchParams.set(key, value);
  });

  return url.toString();
};

const getUserReactions = async ({ contentType, contentId }) => {
  const endpoint = getEndpoint({ contentType, contentId });

  try {
    const response  = await fetch(endpoint, { credentials: 'include' });
    if (!response.ok) {
      return null;
    }
    return response;
  } catch (err) {
    console.error(err);
    return null;
  }
};

const postUserReaction = async ({
  contentId,
  contentType,
  pageId,
  pageType = 'feed',
  reaction,
}) => {
  const endpoint = getEndpoint({ contentType, contentId }, {
    page_type: pageType,
    page_id: pageId,
    reaction: reaction,
    client_uuid: getUserUUID(),
  });

  const options = { method: 'POST', credentials: 'include' };

  try {
    await fetch(endpoint, options);
  } catch (err) {
    console.error(err);
  }
};

/**
 * Normalizes and returns a defined max number of reactions
 * @param {*} data.reactions
 * @param {*} options.max - Maimum to render (newest first) from the total list of reactions.
 *
 * @returns {Array}
 */
const getTopReactions = (
  { reactions = [] },
  { isSponsored = false, max = 2 } = {},
) => {
  return reactions.reduce((result, { count, label }) => {
    if (isSponsored && unsafeEmojis.includes(label)) {
      return result;
    }

    if (count > 0 && result.length < max) {
      result.push(label);
    }

    return result;
  }, []);
};

export const EmojiReactions = ({
  className = '',
  clientSideEnrichment = false,
  contentId,
  contentType = 'content-object',
  data = {},
  isSponsored = false,
  isTrackable = false,
  trackingData = {},
} = {}) => {
  const [ reactions, setReactions ] = useState(getTopReactions(data, { isSponsored }));
  const { trackContentAction } = useTrackingContext();
  const [ userReaction, setUserReaction ] = useState(null);
  const [ totalDisplay, setTotalDisplay ] = useState(data.total_reactions || 0);
  const [ showConfetti, setShowConfetti ] = useState(false);
  const { context_page_id, context_page_type } = trackingData;
  const emojiDialog = useRef();
  const bounceInRef = useRef();

  const reactionCounts = useMemo(() => (
    data?.reactions?.reduce((reactionCounts, reaction) => {
      reactionCounts[reaction.label] = reaction.count || '';

      if (reaction.label === userReaction) {
        reactionCounts[reaction.label] = reaction.count + 1;
      }

      return reactionCounts;
    }, {})
  ), [userReaction]);

  /**
   * Removes the clearAnimation class from the bounceInRef element when showConfetti is true.
   * The classname is added to an element for removing any applied CSS animations to help manage memory by
   * removing the 3D rendering context. When the element needs to animate again, the class is removed.
   * @see http://go.bzfd.it//css-performance--manage-layer-count
   */
  useLayoutEffect(() => {
    if (showConfetti) {
      const el = bounceInRef.current;
      el.classList.remove(styles.clearAnimation);
    }
  }, [showConfetti]);

  /**
   * If the user has already reacted to this contentType:contentId, display their reaction.
   */
  useEffect(() => {
    const localUserReaction = userEmojis.get(`${contentType}:${contentId}`);
    if (localUserReaction) {
      // Reactions are saved as `{contentType}:{contentId}:{reaction}`. We only need the last
      // element (reaction).
      const reaction = localUserReaction.split(':').pop();
      if ((!isSponsored && emojisList.includes(reaction)) || (isSponsored && safeEmojis.includes(reaction))) {
        setUserReaction(reaction);
      }
    }
  }, [contentId, contentType]);

  /**
   * To allow this component to work in contexts outside of feed pages (where content reactions
   * enrichment may not be available), this useEffect can fetch reactions on the client.
   */
  useEffect(() => {
    const fetchData = async () => {
      /**
       * Prevent fetching content reactions on the client if any of these conditions are true:
       *  - `clientSideEnrichment` is false
       *  - `clientSideEnrichment` is true AND there are no reactions from the server rendering.
       *  - `contentId` is not defined.
       */
      if (!clientSideEnrichment || !contentId || (clientSideEnrichment && data.reactions?.length)) {
        return;
      }

      try {
        const results = await getUserReactions({ contentId, contentType });
        const { reactions: reactionsResults = [], total_reactions = 0 } = await results?.json?.() ?? {};

        // Top two emojis
        const topReactions = getTopReactions({ reactions: reactionsResults }, { isSponsored });

        if (total_reactions >= minReactions && topReactions.length) {
          setTotalDisplay(total_reactions);
          setReactions(topReactions);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Makes sure the emoji dialog popup does not occur offscreen, moving it into view when needed.
   */
  const setDialogueInViewport = () => {
    const el = emojiDialog.current;
    const rect = el.getBoundingClientRect();
    const clientWidth = document.documentElement.clientWidth;

    if (rect.right > clientWidth) {
      el.style.setProperty(
        '--left-offset',
        `calc(${Math.ceil(rect.right - clientWidth)}px + 1rem)`
      );
    }
    else if (rect.left < 0) {
      el.style.setProperty(
        '--left-offset',
        `calc(${Math.floor(rect.left)}px - 1rem)`
      );
    }
  }

  const showDialogClickHandler = () => {
    const el = emojiDialog.current;

    if (el.open) {
      return;
    }

    el.removeAttribute('style');
    el.show();
    setDialogueInViewport();

    // Adding the event handler on the next event loop or the handler will be invoked immediatly in
    // the same click event that triggered this handler (the dialog would immediatly close).
    requestAnimationFrame(() => {
      document.addEventListener('click', handleClickOutside, { once: true });
    });
  };

  /**
   * Closing first involves adding a classname to start the closing animation. The dialogs `close()`
   * function will then fire on the `animationend` event.
   * @param {function} options.onAnimationEnd - A callback function to be invoked after the dialog
   * has closed.
   */
  const closeDialog = ({ onAnimationEnd } = {}) => {
    const el = emojiDialog.current;
    const animateClassName = styles.animateClose;

    el.addEventListener('animationend', function animationEndHandler(event) {
      if ([styles.bounceOut, styles.fadeOut].includes(event.animationName)) {
        el.close();
        el.classList.remove(animateClassName);
        el.removeEventListener('animationend', animationEndHandler);

        if (typeof onAnimationEnd === 'function') {
          onAnimationEnd();
        }
      }
    });

    el.classList.add(animateClassName);
  };

  const handleClickOutside = (event) => {
    const el = emojiDialog.current;

    // If the current click target is the dialog itself, don't close it.
    if (el && !el.contains(event.target)) {
      closeDialog();
    }
  };

  const emojiSelectionClickHandler = (event) => {
    if (event.target.tagName.toLowerCase() !== 'button') {
      return;
    }

    closeDialog({
      onAnimationEnd: () => {
        setShowConfetti(true);
      }
    });

    const reaction = event.target.innerText.trim();
    if (isTrackable) {
      // remove target_content_id, target_content_type, target_content_url from trackingData
      const {
        target_content_id = '',
        target_content_type = '',
        target_content_url = '',
        ...actionTrackingData
      } = trackingData;
      trackContentAction({
        ...actionTrackingData,
        item_type: 'button',
        action_type: 'react',
        action_value: reaction,
      });
    }

    const newReactionsCount = { ...reactionCounts };
    // Do not submit the new emoji or incremint emoji count if a user has already reacted
    // previously.
    if (!userReaction) {
      // Update if the minimum emojis are displayed. This helps to prevent showing the users choice
      // with a number less than the minimum. Example, on an objects first reaction, we would want
      // to prevent: "🤯 1"
      if (totalDisplay >= minReactions) {
        setTotalDisplay(totalDisplay + 1);
      }

      postUserReaction({
        contentId,
        contentType,
        pageId: context_page_id,
        pageType: context_page_type,
        reaction,
      });
    } else if (newReactionsCount[userReaction] >  0) {
      newReactionsCount[userReaction] = newReactionsCount[userReaction] - 1;
    }
    newReactionsCount[reaction] = newReactionsCount[reaction] + 1;

    setUserReaction(reaction);
    userEmojis.set(`${contentType}:${contentId}:${reaction}`);

    document.removeEventListener('click', handleClickOutside);
  };

  /**
   * When the confetti animation has completed, trigger the onTransitionEndComplete callback.
   */
  const onConfettiEnd = () => setShowConfetti(false);

  /**
   * Remove the bounceIn animation class after the animation has completed. This is done to remove
   * the 3D rendering context from the element, which can help manage memory.
   */
  const clearBounceInAnimation = (event) => {
    if (event.animationName === styles.bounceIn) {
      const el = bounceInRef.current;
      el.classList.add(styles.clearAnimation);
    }
  }

  const hasMinimumReactions = !!reactions?.length && totalDisplay >= minReactions;

  return (
    <section className={`${className} ${styles.reactions}`}>
      <ul
        aria-label="Emoji Reactions"
        aria-controls="dialog"
        className={styles.list}
        onClick={showDialogClickHandler}
        role="button"
        tabIndex="0"
      >
        {reactions.map((reaction, index) => (reaction !== userReaction &&
          <li
            key={`top-emoji-${index}`}
            className={styles.listItem}
          >{reaction}</li>
        ))}

        {userReaction &&
          <li className={`${styles.listItem} ${styles.userReaction}`}>
            <span
              className={styles.openEmojiDialogBtn}
              onAnimationEnd={clearBounceInAnimation}
              ref={bounceInRef}
            >
              {userReaction}
            </span>

            {showConfetti &&
              <Confetti className={styles.confetti} emoji={userReaction} onTransitionEndComplete={onConfettiEnd} />
            }
          </li>
        }

        {hasMinimumReactions &&
          <li className={styles.total}>
            <span aria-label="Total Emoji Reactions:">{formatNumberToShorthand(totalDisplay)}</span>
          </li>
        }

        {!userReaction &&
          <li className={styles.addNewEmoji}>
            <span className={styles.openEmojiDialogBtn}>
              <EmojiReactionAdd />{!reactions?.length && <span className={styles.react} aria-label="React">React</span>}
            </span>
          </li>
        }
      </ul>

      <dialog aria-modal="true" className={styles.dialog} ref={emojiDialog}>
        <ul aria-label="Select an emoji reaction" className={styles.list} onClick={emojiSelectionClickHandler}>
          <>
            {safeEmojis.map(emoji => (
              <li key={emoji}><button disabled={userReaction === emoji}>{emoji}</button>{hasMinimumReactions && <span className={styles.reactionCount}>{reactionCounts[emoji]}</span>}</li>
            ))}
          </>
          <>
            {!isSponsored && unsafeEmojis.map(emoji => (
              <li key={emoji}><button disabled={userReaction === emoji}>{emoji}</button>{hasMinimumReactions && <span className={styles.reactionCount}>{reactionCounts[emoji]}</span>}</li>
            ))}
          </>
        </ul>
      </dialog>
    </section>
  );
};

EmojiReactions.propTypes = {
  className: PropTypes.string,
  clientSideEnrichment: PropTypes.bool,
  contentId: PropTypes.number.isRequired,
  contentType: PropTypes.string,
  data: PropTypes.object.isRequired,
  isSponsored: PropTypes.bool,
  isTrackable: PropTypes.bool,
  trackingData: PropTypes.object,
};

export default EmojiReactions;
