import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { PageContext, StickyContext } from '../../../contexts';

const AdPlaceholder = ({
  className = '',
  children,
  renderChildren = true,
}) => {
  const stickyContext = useContext(StickyContext);
  const { adsDisabled } = useContext(PageContext);
  const [stickyTop, setStickyTop] = useState(0);

  useEffect(() => {
    const topmostPosition = stickyContext?.getTopmostPosition();
    setStickyTop(Number.isFinite(topmostPosition) ? topmostPosition : 0);
  }, [stickyContext]);

  if (adsDisabled) {
    return null;
  }

  return (
    <aside className={`ad-placeholder ${className}`} style={{'--ad-sticky-top': `${stickyTop}px`}}>
      {renderChildren && children}
    </aside>
  );
};

AdPlaceholder.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  renderChildren: PropTypes.bool,
};

export default AdPlaceholder;
