import { useState, useEffect } from 'react';
import { useLinkTracking, useMediaQuery } from '../../hooks';
import styles from './pageHeader.module.scss';

export const PageHeader = ({ item,  trackingData: unitTrackingData}) => {
  const { breakpoint } = useMediaQuery();
  const { content, id: contentObjectId, object_type: objectType } = item || {};
  const { badge, images, title, description, trackingData } = content || {};

  const [imageInfo, setImageInfo] = useState({
    alt: images?.mobile_alt_text || images?.standard_alt_text || title,
    credit: images?.mobile_credit || images?.standard_credit || '',
  });

  const subunitTrackingData = {
    subunit_type: 'component',
    subunit_name: `${objectType}|${contentObjectId}`,
    position_in_subunit: 0,
  };
  const sharedTrackingData = {
    ...unitTrackingData,
    ...trackingData,
    ...subunitTrackingData
  };
  useEffect(() => {
    if (!breakpoint) {
      return;
    }

    if (breakpoint !== 'xs') {
      setImageInfo({...imageInfo, credit: images?.standard_credit || ''});
    } else {
      setImageInfo({...imageInfo, credit: images?.mobile_credit || ''});
    }

  }, [breakpoint]);

  const clickDescriptionRef = useLinkTracking(sharedTrackingData);

  if (
    !title?.length
    && !description?.length
    && !images?.standard?.length
    && !images?.mobile?.length
    && !badge?.source?.length
  ) {
    return null;
  }
  return (
    <header className={styles.pageHeader}>
      {(images?.standard || images?.mobile) &&
        <figure className={styles.imageContainer}>
          <picture>
            {/* credit (shared) */}
            {!!imageInfo.credit.length &&
              <figcaption className={`${styles.credit} `}>{imageInfo.credit}</figcaption>}
            {/* Mobile image */}
            {!!images?.mobile?.length && (<source media="(max-width: 39.99rem)" srcSet={images.mobile}/>)}
            {/* Standard (default) image */}
            <img src={images?.standard} alt={imageInfo.alt}/>
          </picture>
        </figure>
      }
      {(!!title?.length || !!description?.length || !!badge?.source?.length) && (
        <div className={styles.textWrapper}>
          {(!!badge?.source?.length || !!title?.length) && (
            <h1 className={styles.title}>
              {!!badge?.source.length && <img src={badge.source} alt="" aria-hidden="true" />}
              {title}
            </h1>
          )}
          {description &&
            <div className={styles.description} dangerouslySetInnerHTML={{__html: description}} ref={clickDescriptionRef}></div>}
        </div>
      )}
    </header>
  );
};

export default PageHeader;
