import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import AdsProvider from '@buzzfeed/adlib/dist/module/bindings/react/components/AdsContext';
import { getPageContext } from '../../utils/ads/context';
import { PageContext } from '../../contexts';
import useABeagleAsync from '../../hooks/useABeagleAsync';
// TODO: FInd a way to remove this dependency?
import useLegacyTracking from '../../hooks/tracking/useLegacyTracking';
import { serviceAbTests } from '../../constants/ab-tests';

/* eslint-disable camelcase */
async function getPageTargeting({ pageName, pagePath, abeagle }) {
  const pageTargeting = pageName !== 'topic' ? {
    pagetype: 'A',
    destination: ['newhp', 'buzzfeedhomepage', 'buzzfeed'],
  } : {
    destination: ['topicpage'],
    topic: pagePath,
  };

  // Add experiments to abtest targeting
  const experiments = serviceAbTests.map(({ name }) => name);

  const experimentVariants = await Promise.all(
    experiments.map(experiment =>
      abeagle.getExperimentVariant(experiment, {
        rejectErrors: false,
        defaultVariantIfUnbucketed: null,
      })
    )
  );
  pageTargeting.abtest = experiments.map(
    (experiment, idx) => `${experiment}|${experimentVariants[idx]}`
  );

  return pageTargeting;
}
/* eslint-enable camelcase */

export default function Ads({
  edition = 'en-us',
  pagePath = 'hp',
  pageName,
  children,
}) {
  const abeagle = useABeagleAsync();
  const legacyTracking = useLegacyTracking();
  const { adsDisabled } = useContext(PageContext);

  const getPageContextBound = useCallback(
    () =>
      getPageContext({ edition, abeagle, tracking: legacyTracking, pageName }),
    [edition, abeagle, legacyTracking, pageName]
  );
  const getPageTargetingBound = useCallback(
    () => getPageTargeting({ pageName, pagePath, abeagle }),
    [abeagle]
  );

  return (
    <AdsProvider
      pageId={pagePath}
      pageLanguage={edition}
      adsEnabled={!adsDisabled}
      getPageContext={getPageContextBound}
      getPageTargeting={getPageTargetingBound}
    >
      {children}
    </AdsProvider>
  );
}

Ads.propTypes = {
  edition: PropTypes.string,
  pagePath: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
