import { useEffect, useRef } from 'react';
import { useTrackingContext } from './useTrackingContext';

export function useLinkTracking(trackingData) {
  const ref = useRef(null);
  const { trackClientInternalLinkHandler, trackClientExternalLinkHandler } = useTrackingContext();

  useEffect(() => {
    const element = ref.current;
    let cleanup;

    if (!element) {
      return;
    }

    element.querySelectorAll('a[href]').forEach((link, index) => {
      const isExternalLink = !link.href ? false : !link.href.toLowerCase().match(/.*buzzfeed\.(com|io)|(^\/.*$)/);
      const commonData = {
        item_type: 'text',
        item_name: link.text,
        position_in_unit: index,
      }

      if (isExternalLink) {
        cleanup = trackClientExternalLinkHandler(link, {
            ...trackingData,
            ...commonData,
            target_content_url: link.href,
        })
      } else {
        cleanup = trackClientInternalLinkHandler(link, {
          ...trackingData,
          ...commonData,
          target_content_type: 'url',
          target_content_id: link.href,
        })
      }
    });
    return cleanup;
  }, [ref.current, trackingData]);

  return ref;
}
