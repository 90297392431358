import PropTypes from 'prop-types';

import SingleCard from '../FeedCard/SingleCard';
import SplashCard from '../FeedCard/SplashCard';
import FeedSocial from '../FeedSocial';
import BFPContent from '../BFPContent';
import Package from '../Package';
import Voting from '../FunModule/Voting';
import AddYoursDiscussion from '../FunModule/AddYoursDiscussion';
import PageHeader from '../PageHeader';

/**
 * @todo
 * Upgrade Next.js before using `next/dynamic` instead of static imports for components. Example:
 * `{ post_promo: dynamic(() => import('../FeedCard/SingleCard')) }`
 * This will allow initial server rendering of feed items, with later items lazy loaded to optimize
 * client-side bundles. See the following github issue for context:
 * https://github.com/vercel/next.js/pull/64294
 */
export const COMPONENT_MAP = {
  bfp_content: BFPContent,
  discussion_question: AddYoursDiscussion,
  package: Package,
  'post_promo:small': SingleCard,
  'post_promo:medium': SingleCard,
  'post_promo:large': SplashCard,
  social_embed: FeedSocial,
  voting: Voting,
  page_header: PageHeader,
};

/**
 * @param {object} props.item - Item to render, which includes content, as well as the id and
 * @param {string} props.item.object_type
 */
const ContentComponent = ({ displayOptions, ...props }) => {
  const { object_type } = props?.item || {};
  const componentProps = { ...props };
  let Component = COMPONENT_MAP[object_type];

  if (object_type === 'post_promo') {
    const { grid, post_promo_size = 'small' } = displayOptions || {};
    Component = COMPONENT_MAP[`post_promo:${post_promo_size}`] || COMPONENT_MAP['post_promo:small'];

    componentProps.variant = post_promo_size;
    componentProps.parentLayout = grid;

    if (post_promo_size === 'large' && grid === 'two_columns') {
      componentProps.imageRatioTablet = '1/1';
      componentProps.imageRatioDesktop = '1/1';
    }
  }

  return Component ?
    <Component {...componentProps} /> : null;
};

ContentComponent.propTypes = {
  className: PropTypes.string,
  displayOptions: PropTypes.object,
  item: PropTypes.object.isRequired,
  index: PropTypes.number,
  isTrackable: PropTypes.bool,
  popularLabel: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
  showSection: PropTypes.bool,
  trackingData: PropTypes.object,
};

export default ContentComponent;
